import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { Route } from '@shared-models/server-route'
import { DomFormDataBuilder } from '@shared-models/server-route/form-data-builder'
import { map, Observable, tap } from 'rxjs'
import { ApiClient, unit } from './interface'

export class ApiClientLive extends ApiClient {
  private readonly httpClient = inject(HttpClient)

  override apiRequest(route: Route): Observable<unknown> {
    const httpRequest = route.print(() => new DomFormDataBuilder()).toAngular()

    return this.httpClient.request(httpRequest.method, httpRequest.url, {
      body: httpRequest.body,
      headers: httpRequest.headers,
      params: httpRequest.params,
    }).pipe(
      map((value) => value !== null ? value : unit),
      tap({ error: console.warn }),
    )
  }
}
